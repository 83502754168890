<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.product") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div class="flex h-full flex-col">
        <h2 class="mb-2">{{ t("headings.info") }}</h2>
        <div class="card p-4">
          <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <label class="block">{{ t("labels.name") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ product.name }}</span>
            </div>
            <div>
              <label class="block">{{ t("labels.available") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{
                product.available ? t("labels.yes") : t("labels.no")
              }}</span>
            </div>
            <div>
              <label class="block">{{ t("labels.description") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ product.description }}</span>
            </div>
            <div>
              <label class="block">{{ t("labels.price") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ product.price / 100 }}</span>
            </div>
          </div>
          <hr class="my-4 border-slate-300" />
          <div class="flex justify-end">
            <form-button
              :command="() => router.push(`/products/${product.id}/edit`)"
              :disabled="waiting"
              id="product-edit-button"
              label="edit"
              :textVariant="company.primaryText || 'light'"
              type="button"
              :variant="company.primary || 'indigo'"
            />
          </div>
        </div>
      </div>
      <div class="flex h-full flex-col">
        <h2 class="mb-2">{{ t("headings.estates") }}</h2>
        <div class="card p-4">
          <custom-table
            :fields="fields"
            :items="estates"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="searchFields"
            :showHeader="true"
            sortBy="name"
            :sortFields="sortFields"
            @row-click="(id) => router.push(`/estates/${id}`)"
          >
          </custom-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";

export default {
  components: { Breadcrumb, CustomTable, FormButton, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.productEstates);
    const product = computed(() => store.state.product.product);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.product.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "address",
        label: "address",
      },
      {
        key: "postalCode",
        label: "postal_code",
      },
      {
        key: "city",
        label: "city",
      },
    ]);
    const searchFields = ref(["name", "address", "postalCode", "city"]);
    const sortFields = ref(["address", "city", "name", "postalCode"]);

    onMounted(() => {
      if (!product.value.id || product.value.id != route.params.productId) {
        store.dispatch("product/getProduct", route.params.productId);
      } else {
        store.dispatch("estate/getProductEstates", product.value.estateIds);
      }
    });

    watch(
      () => product.value,
      (product) => {
        if (product.id) {
          store.dispatch("estate/getProductEstates", product.estateIds);
        }
      }
    );

    return {
      company,
      estates,
      fields,
      product,
      router,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
